import React from "react"
import { Link } from "gatsby"

const Footer = () => (

  <footer id="colophon" className="site-footer navbar-light" role="contentinfo">
    <div className="pt-3 pb-3 container">
      <div className="site-info">
        <div className="textwidget">
          <p>Copyright Walpole Outdoors 2021</p>
        </div>
      </div>
    </div>
  </footer>
  
)

export default Footer
