import { navigate } from "gatsby"
import React from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, Index } from "react-instantsearch-dom"
import Autocomplete from "./autocomplete"
import qs from "qs"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const alogliaIndex =
  process.env.GATSBY_ALGOLIA_INDEX_NAME || "production_PRODUCTS"

const SearchBox = (props) => {
  const onSuggestionSelected = (_, { suggestion }) => {
    if (suggestion.storeUrl) {
      // Nav to store link
      window.location.assign(
        `${process.env.B2B_COMMERCE_BASEURL}/${suggestion.storeUrl}`
      )
    }
    navigate(suggestion.slug)
  }

  const onSuggestionCleared = () => {}

  const onEnterPressed = (value) => {
    // Build search URL and send to search page
    let queryObj = { query: value }
    let query = `?${qs.stringify(queryObj)}`
    navigate(`/search/${query}`)
  }

  return (
    <>
      <InstantSearch indexName={alogliaIndex} searchClient={searchClient}>
        <Index indexName={alogliaIndex} />
        <Index indexName="production_PROJECTS" />

        <Autocomplete
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionCleared={onSuggestionCleared}
          onEnterPressed={onEnterPressed}
          defaultRefinement={null}
        />
      </InstantSearch>
    </>
  )
}

export default SearchBox
