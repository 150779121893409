import { useEffect, useState } from "react"
import queryString from "query-string"
import Cookies from "js-cookie"

function useGCLID(url, formAssemblyAlias) {
  let [urlInternal, setUrlInternal] = useState()
  useEffect(() => {
    var params = {}
    if (typeof window !== "undefined") {
      let utmzCookie = Cookies.get("__utmz")
      let retries = 0
      if (!utmzCookie) {
        var timer = setInterval(() => {
          utmzCookie = Cookies.get("__utmz")
          if (utmzCookie) {
            // Got our values so cancel timer
            clearInterval(timer)

            params["tfa_59"] = Cookies.get("gclid")
            params["tfa_60"] = Cookies.get("source")
            params["tfa_61"] = Cookies.get("medium")
            params["tfa_62"] = Cookies.get("content")
            params["tfa_63"] = Cookies.get("keyword")
            params["tfa_64"] = Cookies.get("campaign")
            params["tfa_65"] = window.location.pathname

            setUrlInternal(`${url}?${queryString.stringify(params)}`)
          }

          // still didn't get it
          if (retries < 9) retries++
          else {
            clearInterval(timer)
            // Just return the original url
            setUrlInternal(`${url}?${queryString.stringify(params)}`)
          }
        }, 100)
      } else {
        params["tfa_59"] = Cookies.get("gclid")
        params["tfa_60"] = Cookies.get("source")
        params["tfa_61"] = Cookies.get("medium")
        params["tfa_62"] = Cookies.get("content")
        params["tfa_63"] = Cookies.get("keyword")
        params["tfa_64"] = Cookies.get("campaign")
        params["tfa_65"] = window.location.pathname

        setUrlInternal(`${url}?${queryString.stringify(params)}`)
      }
    }
  }, [url, formAssemblyAlias])

  return urlInternal
}

export default useGCLID
