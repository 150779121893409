import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faTwitter,
  faFacebook,
  faPinterest,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import Iframe from "react-iframe"
import useGCLID from "../../hooks/useGCLID"

const FooterInfo = () => {
  //let formUrl = useGCLID("https://www.tfaforms.com/4865964", "tfa_2")
  return (
    <>
      <Row id="footer-widget" className="m-0">
        <Container>
          <Row>
            <Col md={4}>
              <div className="widget_text widget widget_custom_html">
                <h3 className="widget-title">The Walpole Difference</h3>
                <div className="textwidget">
                  <p>
                    The standard all other fence and outdoor structures are
                    measured by.
                  </p>
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/connect/customer-service">Support</Link>
                    </li>
                    <li>
                      <Link to="/about/careers">Careers</Link>
                    </li>
                  </ul>
                  <p>
                    255 Patriot Place
                    <br />
                    Foxborough, MA 02035
                    <br />
                    <a href="tel:18003436948">(800) 343-6948</a>
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="widget_text widget widget_custom_html">
                <h3 className="widget-title">Visit a showroom near you</h3>
                <div className="textwidget">
                  <div className="split">
                    <ul>
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "CT" }}>
                          Connecticut
                        </Link>
                      </li>
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "FL" }}>
                          Florida
                        </Link>
                      </li>                    
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "MA" }}>
                          Massachusetts
                        </Link>
                      </li>                      
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "MO" }}>
                          Missouri
                        </Link>
                      </li>
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "NH" }}>
                          New Hampshire
                        </Link>
                      </li>
                    </ul>
                    <ul>                     
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "NJ" }}>
                          New Jersey
                        </Link>
                      </li>
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "NY" }}>
                          New York
                        </Link>
                      </li>
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "PA" }}>
                          Pennsylvania
                        </Link>
                      </li>
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "RI" }}>
                          Rhode Island
                        </Link>
                      </li>
                      <li>
                        <Link to="/about/our-locations" state={{ abbr: "VA" }}>
                          Virginia
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="widget_text widget widget_custom_html">
                <h3 className="widget-title">Join Our Email List</h3>
                <Iframe
                  url={useGCLID("https://www.tfaforms.com/4865964", "tfa_2")}
                  frameBorder="0"
                  className="newsletterFrame"
                />
                <h3 style={{ marginTop: "25px" }} className="widget-title">
                  Find us on social media
                </h3>
                <div className="textwidget custom-html-widget">
                  <ul>
                    <li>
                      <a
                        href="https://www.instagram.com/walpoleoutdoors/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/WalpoleOutdoors/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.pinterest.com/walpoleoutdoors/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faPinterest} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/walpoleoutdoors/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/user/WalpoleOutdoors"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/WalpoleOutdoors"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  )
}

export default FooterInfo
