import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import withLocation from "../../utils/withLocation"
import Header from "./header"

import FooterInfo from "./footerInfo"
import Footer from "./footer"
import Cookies from "js-cookie"
import { parseUrl } from "query-string"

const Layout = ({ children, className, search }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!Cookies.get("gclid") && search.gclid) {
        Cookies.set("gclid", search.gclid, {
          expires: 1,
          path: "",
        })
      }
    }
  }, [search])

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
      >
        <div className="site">
          <div className="root">
            <Header />
            <div className="site-content">{children}</div>
          </div>

          <FooterInfo />
          <Footer />
        </div>
      </GoogleReCaptchaProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withLocation(Layout)
