import { Link, navigate, useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Nav, Button } from "react-bootstrap"
import SearchBox from "./searchBox"
import MobileNav from "./mobile-nav"
const slugify = require("slugify")
const axios = require("axios")
import Cookies from "js-cookie"

import LOGO from "../../images/Logo-Main.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faShoppingCart } from "@fortawesome/free-solid-svg-icons"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      allPortfolio {
        edges {
          node {
            id
            slug
            category
            categorySlug
            title
          }
        }
      }
      allMenu(
        filter: { enabled: { eq: true } }
        sort: { fields: sequence, order: ASC }
      ) {
        edges {
          node {
            enabled
            name
            displayName
            sequence
            url
          }
        }
      }
    }
  `)

  const [cartCount, setCartCount] = useState()

  useEffect(() => {
    let cartId = Cookies.get("cartId")
    if (cartId)
      axios
        .get(
          `https://us-central1-walpole-web.cloudfunctions.net/cartInfo?cartId=${cartId}`
        )
        .then((response) => setCartCount(response.data.count))
        .catch(() => {})
  }, [])

  const [menuVisible, setMenuVisible] = useState(false)

  const categories = [
    ...new Set(data.allPortfolio.edges.map(({ node }) => node.category)),
  ]

  return (
    <header
      id="masthead"
      className="site-header navbar-static-top navbar-light"
    >
      <Container>
        <div className="head_box">
          <div className="head_box_left">
            <div className="head_box_left_top">
              <div className="logo">
                <Link to="/">
                  <img
                    src={LOGO}
                    alt="Walpole Outdoors"
                    width="314"
                    height="42"
                  />
                </Link>
              </div>
              <ul className="links">
                <li className="phone">
                  <FontAwesomeIcon className="phoneicon" icon={faPhoneAlt} />
                  <a href="tel:18003436948">(800) 343-6948</a>
                </li>

                <li>
                  <SearchBox />
                </li>
                <li>
                  <Button
                    className="headercta"
                    onClick={() => navigate("/connect/schedule-consultation")}
                  >
                    Schedule a Consultation
                  </Button>
                </li>
                <li className="trigger-menu">
                  <MobileNav
                    categories={categories}
                    productCategories={data.allMenu.edges}
                    cartCount={cartCount}
                  ></MobileNav>
                </li>
                <li className="cartic">
                  <a href={`${process.env.B2B_COMMERCE_BASEURL}/ccrz__Cart`}>
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      style={{ marginRight: "10px" }}
                    />
                    My Cart{" "}
                    <span className="badge badge-secondary">{cartCount}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="navbar navbar-expand-md p-0">
              <Nav
                className="collapse navbar-collapse main"
                as="ul"
                id="main-nav"
                onMouseEnter={() => setMenuVisible(true)}
                onMouseLeave={() => setMenuVisible(false)}
              >
                <Nav.Item as="li">
                  <Nav.Link className="navparent">
                    <Link
                      to="/custom-projects"
                      onClick={() => setMenuVisible(false)}
                    >
                      Explore Custom Projects
                    </Link>
                  </Nav.Link>
                  {menuVisible && (
                    <ul className="sub-menu">
                      {categories.map((cat) => {
                        if (cat === "Unknown") return

                        let slug = slugify(cat, {
                          lower: true,
                          remove: /[*+~.()'"!:@]/g,
                          strict: true,
                        })
                        return (
                          <Nav.Item key={cat} as="li">
                            <Link to={`/custom-projects/${slug}`}>{cat}</Link>
                          </Nav.Item>
                        )
                      })}
                    </ul>
                  )}
                </Nav.Item>

                <Nav.Item as="li">
                  <Nav.Link className="navparent">
                    <a
                      onClick={() => {
                        setMenuVisible(false)
                        window.location.href = process.env.B2B_COMMERCE_BASEURL
                      }}
                    >
                      Shop Our Online Catalog
                    </a>
                  </Nav.Link>
                  {menuVisible && (
                    <ul className="sub-menu">
                      {data.allMenu.edges.map(({ node }) => (
                        <Nav.Item as="li" key={node.displayName}>
                          <Nav.Link href={node.url}>
                            {node.displayName}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </ul>
                  )}
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="navparent">
                    <Link to="/connect" onClick={() => setMenuVisible(false)}>
                      Connect with Us
                    </Link>
                  </Nav.Link>
                  {menuVisible && (
                    <ul className="sub-menu">
                      <Nav.Item as="li">
                        <Link to="/connect/schedule-consultation">
                          Schedule a Consultation
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link to="/connect/customer-service">
                          Contact Customer Service
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link
                          href="https://www.gallery.walpoleoutdoors.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Virtual Showroom
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link to="/about/our-locations">
                          Find Your Local Design Center
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link to="/connect/newsletter">
                        Join Our Email List
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link to="/connect/catalog">Request a Catalog</Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link to="/connect/social">Connect on Social</Link>
                      </Nav.Item>
                    </ul>
                  )}
                </Nav.Item>

                <Nav.Item as="li">
                  <Nav.Link className="navparent">
                    <Link to="/about" onClick={() => setMenuVisible(false)}>
                      About Walpole
                    </Link>
                  </Nav.Link>
                  {menuVisible && (
                    <ul className="sub-menu">
                      <Nav.Item as="li">
                        <Link to="/about/our-locations">Our Locations</Link>
                      </Nav.Item>
                      {/* <Nav.Item as="li">
                        <Link to="/about/message-ceo">
                          A Message from our CEO
                        </Link>
                      </Nav.Item> */}
                      <Nav.Item as="li">
                        <Link to="/about/privacy-policy">Legal Notices</Link>
                      </Nav.Item>

                      <Nav.Item as="li">
                        <Link to="/blog">Blog</Link>
                      </Nav.Item>

                      <Nav.Item as="li">
                        <Link to="/about/partnerships">Partnerships</Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link to="/about/careers">Careers</Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link to="/about/walpole-difference">The Walpole Difference</Link>
                      </Nav.Item>
                    </ul>
                  )}
                </Nav.Item>
              </Nav>
            </div>
            {/*<div className="mobcart carticmob">
                  <a href={`${process.env.B2B_COMMERCE_BASEURL}/ccrz__Cart`}>
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      style={{ marginRight: "10px" }}
                    />
                    My Cart
                  </a>
                  </div>*/}
            <div className="mobsearch">
              <SearchBox />
            </div>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header
