import React from "react"
import { slide as Menu } from "react-burger-menu"
import { Button } from "react-bootstrap"
import { Link, navigate } from "gatsby"
const slugify = require("slugify")
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faShoppingCart } from "@fortawesome/free-solid-svg-icons"

const MobileNav = ({ categories, productCategories, cartCount }) => {
  return (
    <Menu right>
      {/*<SearchBox />*/}
      <div className="mobcart carticmob">
        <a href={`${process.env.B2B_COMMERCE_BASEURL}/ccrz__Cart`}>
          <FontAwesomeIcon
            icon={faShoppingCart}
            style={{ marginRight: "10px" }}
          />
          My Cart <span className="badge badge-secondary">{cartCount}</span>
        </a>
      </div>
      <div className="mobile-cta">
        <FontAwesomeIcon className="phoneicon" icon={faPhoneAlt} />
        <a id="mobilenumber" href="tel:18003436948">
          800-343-6948
        </a>
        <Button onClick={() => navigate("/connect/schedule-consultation")}>
          Schedule a Consultation
        </Button>
      </div>
      <ul>
        <li>
          <Link className="mobile-menu-item" to="/custom-projects">
            Explore Custom Projects
          </Link>
          <ul className="mobile-child">
            {categories.map((cat) => {
              if (cat === "Unknown") return

              let slug = slugify(cat, {
                lower: true,
                remove: /[*+~.()'"!:@]/g,
                strict: true,
              })
              return (
                <li key={cat}>
                  <Link
                    className="mobile-menu-item"
                    to={`/custom-projects/${slug}`}
                  >
                    {cat}
                  </Link>
                </li>
              )
            })}
          </ul>
        </li>
        <li>
          <a
            className="mobile-menu-item"
            href={`${process.env.B2B_COMMERCE_BASEURL}`}
          >
            Shop Our Online Catalog
          </a>
          <ul className="mobile-child">
            {productCategories.map(({ node }) => (
              <li key={node.displayName}>
                <a className="mobile-menu-item" href={node.url}>
                  {node.displayName}
                </a>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <Link className="mobile-menu-item" to="/connect">
            Connect with Us
          </Link>
          <ul className="mobile-child">
            <li>
              <Link
                className="mobile-menu-item"
                to="/connect/schedule-consultation"
              >
                Schedule a Consultation
              </Link>
            </li>
            <li>
              <Link className="mobile-menu-item" to="/connect/customer-service">
                Contact Customer Service
              </Link>
            </li>
            <li>
              <a
                className="mobile-menu-item"
                href="https://www.gallery.walpoleoutdoors.com/"
                target="_blank"
                rel="noreferrer"
              >
                Virtual Showroom
              </a>
            </li>
            <li>
              <Link className="mobile-menu-item" to="/about/our-locations">
                Find Your Local Design Center
              </Link>
            </li>
            <li>
              <Link className="mobile-menu-item" to="/connect/newsletter/">
              Join Our Email List
              </Link>
            </li>
            <li>
              <Link className="mobile-menu-item" to="/connect/catalog/">
                Request a Catalog
              </Link>
            </li>
            <li>
              <Link className="mobile-menu-item" to="/connect/social">
                Connect on Social
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link className="mobile-menu-item" to="/about">
            About Walpole
          </Link>
          <ul className="mobile-child">
            <li>
              <Link className="mobile-menu-item" to="/about/our-locations">
                Our Locations
              </Link>
            </li>
            {/* <li>
              <Link to="/about/message-ceo">A Message from our CEO</Link>
            </li> */}
            <li>
              <Link className="mobile-menu-item" to="/about/privacy-policy">
                Legal Notices
              </Link>
            </li>

            <li>
              <Link className="mobile-menu-item" to="/blog">
                Blog
              </Link>
            </li>

            <li>
              <Link className="mobile-menu-item" to="/about/partnerships">
                Partnerships
              </Link>
            </li>
            <li>
              <Link
                className="mobile-menu-item"
                to="/about/careers"
                target="_blank"
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                className="mobile-menu-item"
                to="/about/walpole-difference"
                target="_blank"
              >
                The Walpole Difference
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </Menu>
  )
}
export default MobileNav
