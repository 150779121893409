import React, { Component, useState, navigate } from "react"
import { Highlight, connectAutoComplete } from "react-instantsearch-dom"
import AutoSuggest from "react-autosuggest"
import theme from "./autocomplete.module.css"
import { Link } from "gatsby"
import algoliasearch from "algoliasearch/lite"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import PLACEHOLDER from "../../images/wo-placeholder.png"

const alogliaIndexName =
  process.env.GATSBY_ALGOLIA_INDEX_NAME || "production_PRODUCTS"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)
const index = searchClient.initIndex(alogliaIndexName)

class Autocomplete extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.currentRefinement,
      categories: null,
      manufacturers: null,
      suggestionHighlighted: false,
    }
  }

  onChange = (event, { newValue, method }) => {
    if (!newValue) {
      this.props.onSuggestionCleared()
    }

    this.setState({ value: newValue })
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (!this.state.suggestionHighlighted)
        this.props.onEnterPressed(this.state.value)
      event.target.blur()
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value)
    // index
    //   .searchForFacetValues("categories.lvl1", value)
    //   .then(({ facetHits }) => {
    //     //console.log(facetHits);
    //     let categories = facetHits.map((hit) => ({ ...hit, type: "category" }))
    //     this.setState({ categories: categories })
    //   })
    // index
    //   .searchForFacetValues("manufacturerName", value)
    //   .then(({ facetHits }) => {
    //     let manufacturers = facetHits.map((hit) => ({
    //       ...hit,
    //       type: "manufacturer",
    //     }))
    //     //console.log(manufacturers);
    //     this.setState({ manufacturers: manufacturers })
    //   })
  }

  onSuggestionsClearRequested = () => {
    this.props.refine()
  }

  getSuggestionValue(hit) {
    if (hit.title) return hit.title
    return hit.name
  }

  renderSuggestion(hit) {
    if (hit.title) {
      return (
        // Custom Project
        <div>
          <span className={theme.thumbnail}>
            <img
              className={theme.thumbnailImage}
              src={hit.imageUrl ? hit.imageUrl : PLACEHOLDER}
            />
          </span>
          <span className={theme.header}>
            <Highlight attribute="title" hit={hit} tagName="mark" />
          </span>
        </div>
      )
    } else {
      return (
        // Products
        <div>
          <span className={theme.thumbnail}>
            <img
              className={theme.thumbnailImage}
              src={hit.imageUrl ? hit.imageUrl : PLACEHOLDER}
            />
          </span>
          <span className={theme.header}>
            <Highlight attribute="name" hit={hit} tagName="mark" />
          </span>
        </div>
      )
    }
  }

  renderSectionTitle(section) {
    return <span className={theme.sectionTitle}>{section.title}</span>
  }
  getSectionSuggestions(section) {
    return section.results
  }

  onSuggestionHighlighted = ({ suggestion }) => {
    //console.log(`Highlighted: ${suggestion}`)
    this.setState({ suggestionHighlighted: suggestion ? true : false })
  }

  renderInputComponent = (inputProps) => {
    return (
      <div>
        <input {...inputProps} />

        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => this.props.onEnterPressed(this.state.value)}
        />
        <button
          className="searchbtn"
          onClick={() => this.props.onEnterPressed(this.state.value)}
        >
          Search
        </button>
      </div>
    )
  }

  render() {
    const { hits, onSuggestionSelected } = this.props
    const { value } = this.state

    const inputProps = {
      placeholder: "",
      onChange: this.onChange,
      value,
      onKeyDown: this.onKeyDown,
      ref: this.textInput,
    }

    let combined = []
    if (hits) {
      let products = hits.filter(
        (results) => results.index === "production_PRODUCTS"
      )[0]
      if (products && products.hits)
        combined.push({
          title: "PRODUCTS",
          results: products.hits.slice(0, 10),
        })

      let projects = hits.filter(
        (results) => results.index === "production_PROJECTS"
      )[0]
      if (projects && projects.hits)
        combined.push({
          title: "CUSTOM PROJECTS",
          results: projects.hits.slice(0, 10),
        })
    }

    return (
      <AutoSuggest
        multiSection={true}
        suggestions={combined}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        renderSectionTitle={this.renderSectionTitle}
        getSectionSuggestions={this.getSectionSuggestions}
        theme={theme}
        renderInputComponent={this.renderInputComponent}
        suggestion={this.state.suggestion}
        onSuggestionHighlighted={this.onSuggestionHighlighted}
      />
    )
  }
}

export default connectAutoComplete(Autocomplete)
