import React from "react"
import { Container, Row } from "react-bootstrap"

const InnerWrapper = ({ children }) => (
  <Container>
    <Row>
      <section className="content-area product">
        <main className="site-main" role="main">
          {children}
        </main>
      </section>
    </Row>
  </Container>
)

export default InnerWrapper
